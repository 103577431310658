import {Container} from "react-bootstrap";

export default function Index() {
    return (
        <Container className="p-3">
            <Container className="p-5 mb-4 bg-light rounded-3">
                <h1 className="header">Это сайт ЦКП ИБГ</h1>
                <p>И он пока в работе</p>
            </Container>
        </Container>
    );
}