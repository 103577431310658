import SidebarMenu from 'react-bootstrap-sidebar-menu';
import 'bootstrap/dist/css/bootstrap.min.css'
import {Outlet} from "react-router-dom"
import {Container, Row, Col} from 'react-bootstrap';


export default function Root() {
    const links = [{title: "Линии мышей", url:"/mice"}, {title: "Оборудование", url:"/equipment"}]
    return (
        <Container>
            <Row>
                <Col>
                <SidebarMenu>
                {links.map((link)=>
                    <SidebarMenu.Nav className="p-3">
                        <SidebarMenu.Nav.Link href={link.url}>
                            <SidebarMenu.Nav.Icon>
                                {/* Menu item icon */}
                            </SidebarMenu.Nav.Icon>
                            <SidebarMenu.Nav.Title>
                                {link.title}
                            </SidebarMenu.Nav.Title>
                        </SidebarMenu.Nav.Link>
                    </ SidebarMenu.Nav>
                )}
            </SidebarMenu>
                </Col>
                <Col>
                    <div id="detail">
                        <Outlet />
                    </div>
                </Col>

            </Row>

        </Container>
    )
}